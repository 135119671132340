import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Button, Switch, Select } from '@arcflight/tf-component-library';
import { editUser } from '../../services/apiNew';

interface EditUserProps {
  organisations: Organisation[];
  user: any;
  handleClose: () => void;
  handleRefresh: () => void;
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

type Organisation = {
  name: string;
  id: string;
};

export type MenuItemDefinition = {
  label: string;
  value: string;
};

const EditUserWrapper = styled.div`
  padding: 20px 65px;
  height: 624px;
`;

const EditUserTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`;

const AdminToggle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

const EditUser: React.FC<EditUserProps> = ({ organisations, user, handleClose, handleRefresh }) => {
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);
  const [submissionError, setSubmissionError] = useState('');
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation>();

  useEffect(() => {
    if (organisations.length > 0) {
      setSelectedOrganisation(organisations[0]);
    }
  }, [organisations]);

  useEffect(() => {
    if (user) {
      const splitName = user.name.split(' ');
      setFirstName(splitName[0]);
      setLastName(splitName[1]);
      setEmail(user.email);
      setIsAdmin(user.role.toLowerCase() === 'admin' || user.role.toLowerCase() === 'administrator');
    }
  }, [user]);

  const validateForm = (): boolean => {
    if (!firstName) {
      setFirstNameError('First name is required');
    }
    if (!lastName) {
      setLastNameError('Last name is required');
    }
    if (!email) {
      setEmailError('Email is required');
    }
    if (!firstName || !lastName || !email) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return false;
    }

    return true;
  };

  const handleEditUser = async (): Promise<void> => {
    if (validateForm()) {
      try {
        const editResponse = await editUser({
          id: user.id,
          firstName,
          lastName,
          email,
          role: isAdmin ? 'Admin' : 'Camo',
          organisation: selectedOrganisation?.name,
          isAdmin,
        });
        if (editResponse.status === 200) {
          setSubmissionError('');
          setFirstName('');
          setLastName('');
          setEmail('');
          setIsAdmin(undefined);
          handleClose();
          handleRefresh();
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setSubmissionError(error.response.data || 'An error occurred');
        } else {
          throw error;
        }
      }
    }
  };
  return (
    <EditUserWrapper>
      <EditUserTitle>Edit User</EditUserTitle>
      <FormWrapper>
        <Input
          label="First Name"
          value={firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setFirstName(e.target.value);
            setFirstNameError('');
          }}
          hasError={!!firstNameError}
          errorMessage={firstNameError}
        />
        <Input
          label="Last Name"
          value={lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setLastName(e.target.value);
            setLastNameError('');
          }}
          hasError={!!lastNameError}
          errorMessage={lastNameError}
        />
        <Input
          label="Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setEmail(e.target.value);
            setEmailError('');
          }}
          hasError={!!emailError}
          errorMessage={emailError}
        />
        {organisations.length > 1 && (
          <div>
            <h4>Organisation</h4>
            <Select
              items={organisations.map((org) => ({ label: org.name, value: org.id })) as MenuItemDefinition[]}
              onSelect={(e: MenuItemDefinition): void =>
                setSelectedOrganisation(organisations.find((org) => org.id === e.value))
              }
            />
          </div>
        )}


        {isAdmin === undefined ? (
          <div />
        ) : (
          <AdminToggle>
            <Switch initialIsChecked={isAdmin} onChange={(): void => setIsAdmin(!isAdmin)} />
            <span>Is admin (this will allow user to create users)</span>
          </AdminToggle>
        )}
      </FormWrapper>
      <Button size={ButtonSize.LARGE} onClick={handleEditUser}>
        Edit User
      </Button>
      {submissionError && <ErrorMessage>{submissionError}</ErrorMessage>}
    </EditUserWrapper>
  );
};

export default EditUser;
